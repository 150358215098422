import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import React, { useContext } from 'react';
import { roundToDecimals } from '../util';
import { TradelogContext } from './context';

const calculatePercentagePoints = (result, total) => {
    return roundToDecimals((result / total) * 100, 2);
};


function separateIntoBaseAndRemainder(value, baseUnit) {
    const base = Math.floor(value / baseUnit) * baseUnit;
    const remainder = value % baseUnit;
    if (remainder < 0) {
        return { base, remainder: baseUnit + remainder };
    }
    return { base,remainder};
}

const prepareWaterfallData = (trades) => {
    // Filter and sort closed trades
    const closedTrades = trades.filter(t => ['managed-profit', 'take-profit', 'managed-loss', 'stop-loss', 'managed-break-even'].includes(t.status))
        .sort((a, b) => dayjs(a.closed_date).diff(dayjs(b.closed_date)));

    let accumulatedPoints = 0;
    let compoundedPoints = 0;
    let startIndex = 100;
    const waterfallData = closedTrades.map((trade, index) => {
        const percentagePoints = calculatePercentagePoints(parseFloat(trade.result), 100); // Assuming the result is in percentage points
        const factor = (1 + (percentagePoints / 100));
        accumulatedPoints = accumulatedPoints + (startIndex * (percentagePoints / 100));

        const { base, remainder } = separateIntoBaseAndRemainder(compoundedPoints, startIndex);
        const current_account = ( ( (startIndex + remainder) * factor) - startIndex);
        compoundedPoints = base + current_account

        return {
            ...trade,
            date: trade.closed_date,
            current_account,
            open_date: dayjs(trade.open_date).format('D MMM'),
            closed_date: dayjs(trade.closed_date).format('D MMM'),
            accumulated: accumulatedPoints,
            compounded: compoundedPoints,
            percentagePoints: percentagePoints,
            // compoundedRbalance,
            itemStyle: { color: percentagePoints >= 0 ? '#00ffdb' : 'red' }
        };
    });

    return waterfallData;
};

const AccumulatedWaterfallChart = () => {
    const api = useContext(TradelogContext);
    if (!api.filteredTrades) return null;
    const data = prepareWaterfallData(api.filteredTrades);

    const option = {
        xAxis: {
            show: false,
            type: 'category',
            data: data.map(d => d.date)
        },
        yAxis: {
            show: false,
            type: 'value',
            axisLabel: {
                formatter: '{value} %'
            }
        },
        series: [
            {
                type: 'bar',
                name: 'Trade',
                data: data.map(d => ({
                    value: d.percentagePoints,
                    itemStyle: d.itemStyle
                })),
                label: {
                    show: false,
                    position: 'top',
                    formatter: '{c} %'
                },
                tooltip: {
                    valueFormatter: function (value) {
                        return `${value.toFixed(2)} %`;
                    }
                }
            },
            {
                type: 'line',
                name: 'Accumulated',
                data: data.map(d => d.accumulated),
                label: {
                    show: false,
                    position: 'top',
                    formatter: '{c} %'
                },
                lineStyle: {
                    color: '#5cc8ff'
                },
                itemStyle: {
                    color: '#5cc8ff'
                },
                tooltip: {
                    valueFormatter: function (value) {
                        return `${value.toFixed(2)} %`;
                    }
                }
            },
            {
                type: 'line',
                name: 'Compounded',
                data: data.map(d => d.compounded),
                label: {
                    show: false,
                    position: 'top',
                    formatter: '{c} %'
                },
                lineStyle: {
                    color: '#dec1ff'
                },
                itemStyle: {
                    color: '#dec1ff'
                },
                tooltip: {
                    valueFormatter: function (value) {
                        return `${value.toFixed(2)} %`;
                    }
                }
            },
        ],
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                const point = data[params[0].dataIndex]
                let tooltipContentArray = [
                    `<b>${point.pair}</b><br/>`,  // Show epic
                    `Open date: <b>${point.open_date}</b><br/>`,  // Show open date
                    `Closed on: <b>${point.closed_date}</b><br/><br/>`,  // Show close date
                    `Current Compound Account: <b>${point.current_account.toFixed(2)}%</b><br/><br/>`  // Show current account
                ];

                params.forEach(item => {
                    tooltipContentArray.push(
                        `${item.marker} ${item.seriesName}: <b>${item.data.value !== undefined ? item.data.value.toFixed(2) : item.data.toFixed(2)}%</b><br/>`
                    );
                });

                return tooltipContentArray.join('');

            }
        },
    };

    return <ReactECharts option={option} style={{ height: '600px', width: '100%' }} />;
};

export default AccumulatedWaterfallChart;
