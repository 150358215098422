import dayjs from "dayjs";
import { useContext, useEffect, useRef, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import { FormGroup, Label } from "reactstrap";
import { TradelogContext } from "./context";

// Constants
const MIN_MONTHS_BACK = -24;
const MAX_MONTHS_BACK = 0;
const DEBOUNCE_DELAY = 500; // Debounce delay in milliseconds

const DateRangeSlider = () => {
  const api = useContext(TradelogContext);
  const [values, setValues] = useState([-6, 0]); // Default to last 6 months
  const debounceRef = useRef(null); // Reference for the debounce timer

  const handleRangeChange = (values) => {
    setValues(values);
  };

  // Convert the range to date labels
  const getMonthLabel = (monthsBack) => dayjs().subtract(Math.abs(monthsBack), 'month').format('MMM YYYY');

  // Debounced filtering function
  const filterTradesByDate = () => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      api.setFilter((prev) => ({
        ...prev,
        fromMonth: values[0],
        toMonth: values[1],
      }));
    }, DEBOUNCE_DELAY);
  };

  useEffect(() => {
    filterTradesByDate();

    // Clean up debounce on component unmount or when values change
    return () => clearTimeout(debounceRef.current);
  }, [...values]);

  return (
    <FormGroup>
      <Label className="text-white">
        {getMonthLabel(values[0])} - {values[1] - values[0]} Month - {getMonthLabel(values[1])}
      </Label>

      <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        <Range
          draggableTrack
          values={values}
          step={1}
          min={MIN_MONTHS_BACK}
          max={MAX_MONTHS_BACK}
          onChange={handleRangeChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values,
                    colors: ["#ccc", "#548BF4", "#ccc"],
                    min: MIN_MONTHS_BACK,
                    max: MAX_MONTHS_BACK,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "26px",
                width: "26px",
                borderRadius: "4px",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA",
              }}
            >
              <div
                style={{
                  height: "16px",
                  width: "5px",
                  backgroundColor: isDragged ? "#548BF4" : "#CCC",
                }}
              />
            </div>
          )}
        />
      </div>
    </FormGroup>
  );
};

export default DateRangeSlider;
