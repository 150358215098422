import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { createContext, useEffect, useState } from 'react';
// Import the functions you need from the SDKs you need

import app from './firebase';

// Create a Context for the Firebase token
export const FirebaseAuthContext = createContext('flflf');

const GoogleSignInWrapper = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [token, setAuth] = useState(null); // State to hold the token

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                setAuth(user)
                setLoggedIn(true);
            } else {
                // No user is signed in, open sign-in popup
                signInWithGooglePopup();
            }
        });

        return () => unsubscribe();
    }, []);

    const signInWithGooglePopup = async () => {
        const auth = getAuth(app);
        const provider = new GoogleAuthProvider();

        provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

        provider.setCustomParameters({
            'login_hint': 'florianderidder@gmail.com'
        });



        try {
            signInWithPopup(auth, provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    const user = result.user;
                    setAuth( user)
                    // The signed-in user info.
                    console.log("the user", user)
                    // IdP data available using getAdditionalUserInfo(result)
                    // ...
                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...
                });
        } catch (error) {
            console.error('Error signing in with Google:', error);
            // Handle errors
        }
    };

    return <FirebaseAuthContext.Provider value={token}> <div>{loggedIn ? children : null}</div></FirebaseAuthContext.Provider>
};

export default GoogleSignInWrapper;
