import { API_URL } from './constants';

export function roundToDecimals(value, decimals) {
    return Number(Number(value).toFixed(decimals));
}

export function roundToTickSize(value, tickSize, decimals) {
    return roundToDecimals(Math.round(value / Number(tickSize)) * Number(tickSize), decimals)
}

export function countDecimals(value) {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
}

export function hourDiff(date1, date2) {
    return ((date2 - date1) / ( 60 * 60) ).toFixed(1) + "H"
    // return dayjs(date2).diff(dayjs(date1), 'hour', true).toFixed(1)+"H"
}



const structure = [
    {
        "tag": "entry_1",
        "r_price_diff": 0.00,
        "r_sl_from_origin_with_entry_2": [-1, -0.5, -0.4],
        "r_sl_from_origin": [-1, -0.75, -0.42],
        "r_tp_from_origin": 1,
        "size_factor": 0.8,
    },
    {
        "tag": "entry_2",
        "r_price_diff": -0.5,
        "r_sl_from_origin_with_entry_2": [-1, -0.75, -0.2],
        "r_sl_from_origin": [-1],
        "r_tp_from_origin": 1,
        "size_factor": 1.2,
    },
    {
        "tag": "entry_3",
        "r_price_diff": 0.25,
        "r_sl_from_origin_with_entry_2": [-0.25, -0.25, 0.5],
        "r_sl_from_origin": [-0.41, -0.25, 0.5],
        "r_tp_from_origin": 1.12,
        "size_factor": 0.6,
    },
    {
        "tag": "entry_4",
        "r_price_diff": 0.50,
        "r_sl_from_origin_with_entry_2": [0, 0.6],
        "r_sl_from_origin": [-0.1, 0.6],
        "r_tp_from_origin": 1.25,
        "size_factor": 0.6
    }
]

export const getOrderList = ({ posSide, price, sl_price_diff, contracts, positionSize, selectedContract }) => {
    if (!price || !posSide) return [];
    const tickSize = selectedContract.tickSize;
    const decimals = selectedContract.pricePrecision;
    // Function to calculate leverage based on margin percentage
    return structure.map((entry, index) => {
        const posSideFactor = posSide === "long" ? 1 : -1;
        const object = ({
            ...entry,
            posSide: "merged",
            symbol: selectedContract.symbol,
            tickSize: tickSize,
            side: posSide === "long" ? "buy" : "sell",
            ordType: "limit",
            entryPrice: roundToTickSize(parseFloat(price) + posSideFactor * (entry.r_price_diff * sl_price_diff), tickSize, decimals),
            slPrice: entry.r_sl_from_origin.map(i => roundToTickSize(parseFloat(price) + posSideFactor * (i * sl_price_diff), tickSize, decimals)),
            slPriceWithEntry2: entry.r_sl_from_origin_with_entry_2.map(i => roundToTickSize(parseFloat(price) + posSideFactor * (i * sl_price_diff), tickSize, decimals)),
            tpPrice: roundToTickSize(parseFloat(price) + posSideFactor * (entry.r_tp_from_origin * sl_price_diff), tickSize, decimals),
            contracts: roundToTickSize( positionSize * entry.size_factor / price, selectedContract.qtyStepSize, selectedContract.qtyPrecision),
        })
        const object2 = {
            ...object,
            profit: roundToDecimals(Math.abs(object.tpPrice - object.entryPrice)  * object.contracts, decimals),
            loss: roundToDecimals(Math.abs(object.slPrice[0] - object.entryPrice) * object.contracts, decimals),
        }
        return {
            ...object2,
            r_r_ratio: roundToDecimals(object2.profit / object2.loss, 2).toFixed(2),
        }
    })
}

export async function callApi(user, method, path, body = false, params = {}) {
    const token = await user.getIdToken();
    let args = {
        method,
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };

    if (body) {
        args = { ...args, body: JSON.stringify(body) };
    }

    // Serialize query parameters into a query string
    const queryString = Object.keys(params)
                              .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
                              .join('&');

    // Append query string to the URL if it exists
    const url = queryString ? `${API_URL}${path}?${queryString}` : `${API_URL}${path}`;

    const response = await fetch(url, args);

    // Check for errors
    if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
        console.error(response);
        return false;
    }
    const data = await response.json();
    return data;
}