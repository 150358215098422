// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";





// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDHtWsetjfc833q2INxHoxJNIBqSdRxbtA",
    authDomain: "drc-trading.firebaseapp.com",
    projectId: "drc-trading",
    storageBucket: "drc-trading.appspot.com",
    messagingSenderId: "264428293708",
    appId: "1:264428293708:web:60146845d6e6dbacf2eee9",
    measurementId: "G-VHQCYBBXM2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

export default app;
