import { createContext, useEffect, useState } from 'react';
// Import the functions you need from the SDKs you need
import dayjs from 'dayjs';
import { useContext, useMemo } from 'react';
import { FirebaseAuthContext } from '../SignIn';
import { callApi } from '../util';
// Create a Context for the Firebase token
export const TradelogContext = createContext({});

export const STATUS = [
    {
        value: "waiting",
        label: "Waiting for setup",
        color: "dark",
        category: "open"
    },
    {
        value: "unfilled",
        label: "Unfilled / Order placed",
        color: "dark",
        category: "open"
    },
    {
        value: "cancelled",
        label: "Cancelled / Didn't take",
        color: "dark",
        category: "cancelled"
    },
    {
        value: "filled",
        label: "Filled / In the trade",
        color: "info",
        category: "open"
    },
    {
        value: "stop-loss",
        label: "SL hit",
        color: "danger",
        category: "closed"
    },
    {
        value: "take-profit",
        label: "TP hit",
        color: "success",
        category: "closed"
    },
    {
        value: "managed-loss",
        label: "Managed loss",
        color: "danger",
        category: "closed"
    },
    {
        value: "managed-profit",
        label: "Managed profit",
        color: "success",
        category: "closed"
    },
    {
        value: "managed-break-even",
        label: "Managed break even",
        color: "warning",
        category: "closed"
    },
];

export const STATUS_FILTER = [
    {value: "open", label: "All Open"},
    {value: "closed", label: "All Closed"},
    ...STATUS
]

export const STRATEGIES = [
    {
        value: "mean-reversion",
        label: "Mean Reversion"
    },
    {
        value: "trend",
        label: "Buy the Dip / Sell the Rip"
    },
    {
        value: "momentum",
        label: "Momentum (Short Hold Time, Small Stop Loss Distance):"
    }
]

const SHOW_ALL = "SHOW_ALL";
const SHOW_FIRST_AND_LAST = "SHOW_FIRST_AND_LAST";
const SHOW_PREVIEW = "SHOW_PREVIEW";
export const VIEWS = {
    SHOW_PREVIEW,
    SHOW_ALL,
    SHOW_FIRST_AND_LAST,
}

const fetchTrades = async (user, filter) => {
    try{
        const data = await callApi(user, "GET", `/tradelog`, false, filter);
        console.log(data);
        const trades = data[0].sort((a, b) => b.open_unix - a.open_unix);
        const comments = data[1].sort((b, a) => b.unix - a.unix);
        return {trades, comments};

    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return {trades:[], comments:[]}
};

// const fetchComments = async (user, filter) => {
//     try {
//         const data = await callApi(user, "GET", `/tradelog/comments`, false, filter);
//         console.log("TRADELOG COMMENTS", data)
//         return data.sort((b, a) => b.unix - a.unix);

//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
//     return []
// };

const filterTrades = (trades, filter) => trades.filter(trade => {

    if(filter.status){
        if(!STATUS.filter(i => i.category === filter.status || i.value === filter.status).map(i => i.value).includes(trade.status)){
            return false;
        }
    }
    if(filter.pair && trade.pair !== filter.pair){
        return false;
    }

    if(filter.strategy && trade.strategy !== filter.strategy){
        return false;
    }
    return true;
});

export function TradelogProvider({ children }) {
    const user = useContext(FirebaseAuthContext); // Access the token
    const [allTrades, setTrades] = useState([]);
    const [comments, setComments] = useState([]);
    const [createModal, setCreateModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [createCommentModal, setCreateCommentModal] = useState(false);
    const [updateCommentModal, setUpdateCommentModal] = useState(false);
    const [view, setView] = useState(VIEWS.SHOW_PREVIEW);
    const [fullscreen, setFullscreen] = useState(false);
    const [pairs, setPairs] = useState([])
    const [filter, setFilter] = useState({
        fromMonth:  -6,
        toMonth: 0,
    })

    const [isLoading, setIsLoading] = useState(false);

    const createTrade = async (data) => {
        const query = {
            ...data,
            open_date: dayjs().format("D MMM YYYY"),
            open_time: dayjs().format("HH:mm"),
            open_unix: dayjs().unix(),
        }
        setIsLoading(true);
        try {
            const data = await callApi(user, "POST", `/tradelog`, query);
            if(data){
                setTrades(prev => [data[0], ...prev]);
                setComments(prev => [...prev, data[1]])
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
        setCreateModal(false);
    };

    const updateTrade = async (query) => {
        setIsLoading(true);
        try {
            const data = await callApi(user, "PUT", `/tradelog/${query.trade_id}`, query);
            if(data){
                setTrades(prev => prev.map(trade => trade.trade_id === query.trade_id ? data[0] : trade));
                setComments(prev => [...prev, data[1]])
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
        setCreateModal(false);
    };

    const deleteTrade = async (trade_id) => {
        setIsLoading(true);
        try {
            const data = await callApi(user, "DELETE", `/tradelog/${trade_id}`);
            if(data){
                setTrades(prev => prev.filter(trade => trade.trade_id !== trade_id));
                setComments(prev => prev.filter(comment => comment.trade_id !== trade_id))
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
    }

    // Comments
    const createComment = async (trade, data) => {
        const {trade_id} = trade;
        const query = {
            ...data,
            trade_id,
            date: dayjs().format("D MMM YYYY"),
            time: dayjs().format("HH:mm"),
            unix: dayjs().unix(),
        }
        setIsLoading(true);
        try {
            const data = await callApi(user, "POST", `/tradelog/${trade_id}/comment`, query);
            if(data){
                setTrades(prev => prev.map(trade => trade.trade_id === trade_id ? data[0] : trade));
                setComments(prev => [...prev, data[1]])
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
        setCreateModal(false);
    };

    const updateComment = async (query) => {
        setIsLoading(true);
        try {
            const data = await callApi(user, "PUT", `/tradelog/${query.trade_id}/comment/${query.comment_id}`, query);
            if(data){
                setTrades(prev => prev.map(trade => trade.trade_id === query.trade_id ? data[0] : trade));
                setComments(prev => prev.map(comment => comment.comment_id === query.comment_id ? data[1] : comment));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
        setCreateModal(false);
    };

    const deleteComment = async (trade_id, comment_id) => {
        setIsLoading(true);
        try {
            const data = await callApi(user, "DELETE", `/tradelog/${trade_id}/comment/${comment_id}`);
            if(data){
                setTrades(prev => prev.map(trade => trade.trade_id === trade_id ? data[0] : trade));
                setComments(prev => prev.filter(comment => comment.comment_id !== comment_id));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
    }



    useEffect(() => {
        // * reset frontend filter
        const getData = async () => {
            const fromUnix = dayjs().subtract(Math.abs(filter.fromMonth), 'month').startOf('month').unix(); // Left thumb
            const toUnix = dayjs().subtract(Math.abs(filter.toMonth), 'month').endOf('month').unix(); // Right thumb (current or selected month)
            const data = await fetchTrades(user, {fromUnix, toUnix});

            setTrades(data.trades);
            setPairs(Object.keys(data.trades.reduce((acc, i) => ({...acc, [i.pair]: true}), {})))
            setComments(data.comments);

        }

        getData();


    }, [user, filter.fromMonth, filter.toMonth]);

    const filteredTrades = useMemo(() => filterTrades(allTrades, filter), [allTrades, filter])

    const api = {
        allTrades,
        filteredTrades,
        comments,
        createModal,
        setCreateModal,
        updateModal,
        setUpdateModal,
        updateTrade,
        deleteTrade,
        fetchTrades,
        // fetchComments,
        createTrade,
        isLoading,
        createComment,
        createCommentModal,
        setCreateCommentModal,
        updateComment,
        deleteComment,
        updateCommentModal,
        setUpdateCommentModal,
        view,
        setView,
        filter,
        setFilter,
        pairs,
        fullscreen,
        setFullscreen,

    }


    return <TradelogContext.Provider value={api}> {children} </TradelogContext.Provider>
}