import ReactECharts from 'echarts-for-react';
import React, { useContext } from 'react';
import { STATUS, TradelogContext } from './context';


const calculatePercentage = (value, total) => {
  if (total === 0) return '0.00';
  const perc = ((value / total) * 100).toFixed(2)
  return `${value} / ${total} = <b>${perc}%</b>`;
};

const filterTradesByStatus = (trades, statusFilter) => {
  return trades.filter(trade => {
    if (!STATUS.filter(i => i.category === statusFilter || i.value === statusFilter).map(i => i.value).includes(trade.status)) {
      return false;
    }
    return true;
  });
};

const prepareData = (trades) => {

  const openTrades = filterTradesByStatus(trades, 'open');
  const closedTrades= filterTradesByStatus(trades, 'closed');

  let counts = STATUS.reduce((acc, status) => ({
    ...acc,
    [status.value]: trades.filter(t => t.status === status.value).length
    }), {});

  counts = {
    ...counts,
    profit: counts['managed-profit'] + counts['take-profit'],
    loss: counts['managed-loss'] + counts['stop-loss'],
    breakEven: counts['managed-break-even'],
    closed: closedTrades.length,
    open: openTrades.length,
    total: trades.length
  }


  return {
    name: 'Trades',
    value: counts.total, // Root value to calculate percentage
    itemStyle: { color: 'rgba(255, 255, 255, 0)' }, // Root background color transparent
    label: { color: '#fff' }, // Root text color
    children: [
      {
        name: 'Open',
        value: counts.open,
        itemStyle: { color: '#c23531' }, // Background color for 'Open'
        label: { color: '#fff' }, // Text color for 'Open'
        tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts.open, counts.total))}` },
        children: [
          {
            name: 'Waiting',
            value: counts.waiting,
            itemStyle: { color: '#2f4554' }, // Background color for 'Waiting for setup'
            label: { color: '#fff' }, // Text color for 'Waiting for setup'
            tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts.waiting, counts.open))}` },
          },
          {
            name: 'Unfilled',
            value: counts.unfilled,
            itemStyle: { color: '#61a0a8' }, // Background color for 'Unfilled / Order placed'
            label: { color: '#000' }, // Text color for 'Unfilled / Order placed'
            tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts.unfilled, counts.open))}` },
          },
          {
            name: 'Filled',
            value: counts.filled,
            itemStyle: { color: '#d48265' }, // Background color for 'Filled / In the trade'
            label: { color: '#000' }, // Text color for 'Filled / In the trade'
            tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts.filled, counts.open))}` },
          },
        ]
      },
      {
        name: 'Closed',
        value: counts.closed,
        itemStyle: { color: '#91c7ae' }, // Background color for 'Closed'
        label: { color: '#000' }, // Text color for 'Closed'
        tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts.closed, counts.total))}` },
        children: [
          {
            name: 'Profit',
            value: counts.profit,
            itemStyle: { color: '#00ff00' }, // Light green background for 'Profit'
            label: { color: '#000' }, // Text color for 'Profit'
            tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts.profit, counts.closed))}` },
            children: [
              {
                name: 'Managed',
                value: counts['managed-profit'],
                itemStyle: { color: '#00cc00' }, // Darker green for 'Managed profit'
                label: { color: '#fff' }, // Text color for 'Managed profit'
                tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts["managed-profit"], counts.closed))}` }
              },
              {
                name: 'TP',
                value: counts['take-profit'],
                itemStyle: { color: '#009900' }, // Darkest green for 'TP hit'
                label: { color: '#fff' }, // Text color for 'TP hit'
                tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts["take-profit"], counts.closed))}` }
              }
            ]
          },
          {
            name: 'Loss',
            value: counts.loss,
            itemStyle: { color: '#ffcccc' }, // Light red background for 'Loss'
            label: { color: '#000' }, // Text color for 'Loss'
            tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts.loss, counts.closed))}` },
            children: [
              {
                name: 'Managed',
                value: counts["managed-loss"],
                itemStyle: { color: '#ff6666' }, // Darker red for 'Managed loss'
                label: { color: '#fff' }, // Text color for 'Managed loss'
                tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts["managed-loss"], counts.closed))}` }
              },
              {
                name: 'SL',
                value: counts["stop-loss"],
                itemStyle: { color: '#cc0000' }, // Darkest red for 'SL hit'
                label: { color: '#fff' }, // Text color for 'SL hit'
                tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts["stop-loss"], counts.closed))}` }
              }
            ]
          },
          {
            name: 'BE',
            value: counts["managed-break-even"],
            itemStyle: { color: '#ffcc99' }, // Light orange background for 'Breakeven'
            label: { color: '#000' }, // Text color for 'Breakeven'
            tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts["managed-break-even"], counts.closed))}` },
            children: [
              {
                name: 'Managed',
                value: counts["managed-break-even"],
                itemStyle: { color: '#ff9933' }, // Darker orange for 'Managed break even'
                label: { color: '#000' }, // Text color for 'Managed break even'
                tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts["managed-break-even"], counts.closed))}` }
              }
            ]
          }
        ]
      },
      {
        name: 'Cancelled',
        value: counts.cancelled,
        itemStyle: { color: '#ffce34' }, // Yellow background for 'Cancelled / Didn't take'
        label: { color: '#000' }, // Text color for 'Cancelled / Didn't take'
        tooltip: { show: true, formatter: (params) => `${params.marker} ${params.name}: ${(calculatePercentage(counts.cancelled, counts.total))}` }
      }
    ]
  };
};



const SunburstChart = () => {
  const api = useContext(TradelogContext);
  if(!api.filteredTrades) return null;
  const data = prepareData(api.filteredTrades);
  const option = {
    series: {
      type: 'sunburst',
      data: [data],
      radius: [0, '90%'],
      label: {
        rotate: 'radial'
      }
    },
    tooltip: {
      trigger: 'item',
    }
  };

  return <ReactECharts option={option} style={{ height: '600px', width: '100%' }} />;
};

export default SunburstChart;
