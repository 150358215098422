import { faArrowAltLeft, faPen, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ForexPair } from '../Screener/Screener';
import { hourDiff } from '../util';
import { TradelogContext } from './context';
import './styles.scss';
import { ResultBadge, StatusBadge, url } from './Tradelog';


const TradeSwiper = () => {
    const api = useContext(TradelogContext);
    const { trade_id } = useParams();
    const navigate = useNavigate();
    const swiperRef = useRef(null);
    const [modal, setModal] = useState(false);
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleModal = () => setModal(!modal);

    const currentTradeIndex = api.allTrades.findIndex((trade) => trade.trade_id === trade_id);

    const navigateToTrade = useCallback((index) => {
        const trade = api.allTrades[index];
        if (trade) {
            navigate(`/log/trade/${trade.trade_id}`);
        }
    }, [api.allTrades, navigate]);

    const handlers = useSwipeable({
        onSwipedUp: () => {
            if (currentTradeIndex < api.allTrades.length - 1 && !api.createCommentModal && !api.updateCommentModal) {
                navigateToTrade(currentTradeIndex + 1);
            }
        },
        onSwipedDown: () => {
            if (currentTradeIndex > 0 && !api.createCommentModal && !api.updateCommentModal) {
                navigateToTrade(currentTradeIndex - 1);
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackTouch: true,
    });

    const handleKeyDown = useCallback((event) => {
        if (!api.createCommentModal && !api.updateCommentModal) {
            if (event.key === 'ArrowUp' && currentTradeIndex > 0) {
                navigateToTrade(currentTradeIndex - 1);
                event.preventDefault();
            } else if (event.key === 'ArrowDown' && currentTradeIndex < api.allTrades.length - 1) {
                navigateToTrade(currentTradeIndex + 1);
                event.preventDefault();
            }
        }
    }, [currentTradeIndex, api.allTrades, navigateToTrade, api.createCommentModal, api.updateCommentModal]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(0);
        }
    }, [trade_id]);

    const trade = api.allTrades.find((t) => t.trade_id === trade_id);
    const comments = api.comments
        .filter((comment) => comment.trade_id === trade_id)
        .map((comment, index, arr) => ({
            ...comment,
            snapshot_img_src: comment.snapshot_img_src || arr[index - 1]?.snapshot_img_src
        }));

    if (!trade) {
        return <div className="text-center text-white">Trade not found</div>;
    }

    const handleImageClick = (imgSrc) => {
        setFullscreenImage(imgSrc);
        toggleModal();
    };

    const comment = comments[activeIndex];

    return (
        <div {...handlers} className="trade-swiper">
            <div className="trade-item">
                <Row>
                    <Col lg="6">
                        <h4 className="mb-0">
                            <a href={url(trade)} target="_blank" rel="noreferrer" className="no-link">
                                {trade.exchange}: <ForexPair pair={trade.pair} />
                            </a>  <StatusBadge status={trade.status} />  <ResultBadge result={trade.result} />
                        </h4>

                    </Col>
                    <Col lg="6" className="text-end">
                        <div className="d-flex my-3 my-lg-0">
                            <Button className="ms-auto me-3" size="sm" color="danger" outline onClick={() => api.deleteComment(comment.trade_id, comment.comment_id)} ><FontAwesomeIcon icon={faTrash} /> Delete</Button>
                            <Button className="me-3" size="sm" color="success" outline onClick={() => api.setUpdateCommentModal(comment)} ><FontAwesomeIcon icon={faPen} /> Edit</Button>
                            {activeIndex === comments.length - 1 && (
                                <Button className="me-3" color="success" size="sm" outline onClick={() => api.setCreateCommentModal(trade)}><FontAwesomeIcon icon={faPlus} /> Comment</Button>
                            )}
                            <Button onClick={() => navigate('/log')} color="light" outline size="sm"><FontAwesomeIcon icon={faArrowAltLeft} /> Close</Button>
                        </div>
                    </Col>
                </Row>
                <Swiper
                    spaceBetween={10}
                    ref={swiperRef}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    keyboard={{ enabled: true, onlyInViewport: true }}
                    modules={[Pagination, Navigation, Keyboard]}
                    className="swiper-h"
                    onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)} // Update the activeIndex on slide change
                >
                    {comments.map((comment) => (
                        <SwiperSlide key={comment.comment_id}>
                            <div className="comment-slide text-white">
                                {comment.snapshot_img_src && (
                                    <div className="pt-3">
                                        <div className="img-background">

                                        <img
                                            alt="Snapshot"
                                            src={comment.snapshot_img_src}
                                            onClick={() => handleImageClick(comment.snapshot_img_src)}
                                            style={{ cursor: 'pointer' }}
                                            />
                                            </div>
                                    </div>
                                )}
                                <div className="d-flex justify-content-between">

                                    <div className="comment-details">
                                        <div className="opacity-70 text-white d-block d-md-none pt-3">
                                            <span className="fw-bold">{comment.time} {comment.date}</span> <em>+{hourDiff(trade.open_unix, comment.unix)}</em>
                                        </div>
                                        {(comment.status || comment.result) && (
                                            <div className="pt-2">
                                                {comment.status && <StatusBadge status={comment.status} size="lg" />}
                                                {comment.result && <ResultBadge result={comment.result} size="lg" />}
                                            </div>
                                        )}
                                        {comment.message && <p className="mb-0 pt-3">{comment.message}</p>}
                                    </div>
                                    <div className="ms-3 opacity-70 text-white d-none d-md-block mt-3 text-end">
                                        <span className="fw-bold">{comment.time} {comment.date}</span> <em>+{hourDiff(trade.open_unix, comment.unix)}</em>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <Modal isOpen={modal} toggle={toggleModal} className="fullscreen-modal" fullscreen>
                <ModalBody className="p-0">
                    {fullscreenImage && (
                        <img src={fullscreenImage} alt="Fullscreen" className="w-100 h-100" style={{ objectFit: 'contain' }} onClick={() => toggleModal()} />
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default TradeSwiper;
